<template>
  <div>
    <div class="list">
      <div class="list-item" v-for="(item,index) in list" :key="index">
        <router-link :to="item.url" class="list-item-title">{{ item.title }}</router-link>
      </div>
    </div>
    <div class="inner-detail">
      <div class="left">
        <div class="title">
          <slot name="title"></slot>
        </div>
        <div class="sub-title"></div>
        <div class="detail">
          <slot name="detail"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'innerDetail',
  data() {
    return {
      list: [
        {
          title: "中正易驾",
          url: "/product/zhongzhengyijia"
        },
        {
          title: "工单系统",
          url: "/product/workOrderSys"
        },
        {
          title: "中正信息科技",
          url: "/product/zhongzhengInfo"
        },
        {
          title: "教务系统",
          url: "/product/teachingSys"
        },
        {
          title: "活动营销小程序",
          url: "/product/activityMarketing"
        }
      ]
    }
  }
}
</script>
<style scoped lang="less">
.list {
  height: 90px;
  display: flex;
  gap: 10px;

  .list-item {
    cursor: pointer;
    border: 1px solid #dddddd;
    border-radius: 6px;
    height: 38px;
    line-height: 38px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 14px;
    padding: 0 20px;
    &:hover{
      background: #01a2e9;
      color: #ffffff;
      transition: all 0.3s;
    }
  }
}

.inner-detail {
  display: flex;
  justify-content: space-between;
  gap: 40px;

  .left {
    width: 100%;
    box-shadow: 0px 0px 30px #dddddd;
    background: #ffffff;
    padding: 40px;

    .title {
      font-weight: bold;
      margin-bottom: 20px;
      font-size: 28px;
      font-weight: bold;
      text-align: center;
      border-bottom: 1px solid #999;
      padding-bottom: 20px;
    }

    .sub-title {
      font-size: 22px;
      color: #000;
    }

    .detail {
      font-size: 16px;
      line-height: 1.8;
      color: #666;
    }
  }
}
</style>

<style scoped lang="less">
.detail-content{
  margin-bottom: 20px;
  font-size: 18px;
  span{
    text-indent: 32px;
    display: inline-block;
  }
}
.detail-img{
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  .admin-img{
    width: calc(100% / 2 - 20px);
  }
  div{
    width:calc(100% / 3 - 40px);
    display: flex;
    align-items: center;
    img{
      width: 100%;
    }
  }
}
</style>
