<template>
    <div>
      <InnerDetail>
        <template v-slot:title>
          {{ $route.meta.title }}
        </template>
        <template v-slot:detail>
          <div class="detail-content">
            <p class="sub-title">项目概述:</p>
            <span>
              用户端采用微信小程序形式上传发布，商家通过后台管理在后台新增活动（拼团、投票、抽奖等活动类型）配置活动信息，通过对应活动列表的分享功能打开微信小程序窗口进行分享实现裂变效果，可实现低成本高收益的拓客效果，实现了订单信息汇总、客户信息留存更配备数据大屏可以实时查看活动数据，大大提高商家拓客裂变能力。
            </span>
          </div>
          <div class="detail-img">
            <div v-for="item in 2" :key="item">
              <img :src="require(`@/assets/img/product/huodong_mini${item}.jpg`)" alt="">
            </div>
            <div style="width: 100%;">
              <img src="@/assets/img/product/huodong1.jpg" alt="">
            </div>
          </div>
        </template>
      </InnerDetail>
    </div>
</template>
<script>
import InnerDetail from "@/views/product/components/InnerDetail.vue";

export default {
    name: 'activityMarketing',
  components: {InnerDetail},
}
</script>
